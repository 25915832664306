import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../API/api";
import genShortid, { formToIsoString, isoToForm, newDate, orderRows, sortByDate, sortByName } from "../../lib/genericUtils";
import * as XLSX from 'xlsx';

// Components
import { Button, Label, Select, Spinner, TextInput, ToggleSwitch } from "flowbite-react";
import ReportForm from "./reports/ReportForm";
import ReportModal from "./reports/ReportModal";
import ReportPreview from "./reports/ReportPreview";
import ReportTable from "./reports/ReportTable";

const FiltersBar = ({ filters, setFilters }) => {

    const handleInput = (e) => {
        const { id, value } = e.currentTarget;
        switch (id) {
            case "days":
                setFilters(prevState => ({ ...prevState, days: value, startDate: '' }));
                break;
            case "startDate":
                setFilters(prevState => ({ ...prevState, days: '', startDate: formToIsoString(`${value}T00:00`) }));
                break;
            case "endDate":
                setFilters(prevState => ({ ...prevState, endDate: formToIsoString(`${value}T23:59`) }));
                break;
            default:
                setFilters(prevState => ({ ...prevState, [id]: value }));
        }
    }

    const resetFilters = () => {
        setFilters({ days: '10', startDate: '', endDate: isoToForm(newDate()) });
    }

    return (
        <div className="flex flex-row flex-wrap gap-8 items-end">
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="days" value="Giorni precedenti" />
                </div>
                <Select
                    id="days"
                    value={filters.days}
                    onChange={handleInput}
                >
                    <option value="">-</option>
                    <option value="3">3</option>
                    <option value="5">5</option>
                    <option value="7">7</option>
                    <option value="10">10</option>
                    <option value="14">14</option>
                    <option value="30">30</option>
                </Select>
            </div>
            <div>
                <div className="mb-2 block text-center">
                    <Label value="Da / A" />
                </div>
                <div className="flex flex-row gap-2">
                    <TextInput
                        id="startDate"
                        type="date"
                        value={filters.startDate && isoToForm(filters.startDate)}
                        onChange={handleInput}
                    />
                    <TextInput
                        id="endDate"
                        type="date"
                        value={isoToForm(filters.endDate)}
                        onChange={handleInput}
                    />
                </div>
            </div>
            <div>
                <Button
                    color="dark"
                    size="sm"
                    className="mb-1"
                    onClick={resetFilters}
                >Reset Filtri</Button>
            </div>
        </div>
    )
}

function Reports() {
    const user = useSelector(state => state.user);
    const season = useSelector(state => state.season);
    const blankReport = {
        _id: undefined,
        shortid: genShortid(),
        valid: false,
        inserted_at: '',
        updated_at: '',
        // Dati Generali
        general: {
            author: user._id,
            match_num: '',
            series: 'CM',
            date: '',
            time: '',
            real_time: '',
            teams: '',
            first_ref: undefined, // ObjectId
            second_ref: undefined, // ObjectId
            scorer: '',
        },
        // Dati Gara
        match: {
            result: '0-0',
            duration: 0,
            spects: 0,
            pts1set: '0-0',
            pts2set: '0-0',
            pts3set: '0-0',
            pts4set: '',
            pts5set: '',
            pts6set: '',
            dur1set: 0,
            dur2set: 0,
            dur3set: 0,
            dur4set: 0,
            dur5set: 0,
            dur6set: 0,
        },
        // Area Immagine
        image: {
            aspect1ref: 2,
            aspect2ref: 2,
            delay1ref: 2,
            delay2ref: 2,
            prot1ref: 2,
            prot2ref: 2,
            whistle1ref: 2,
            whistle2ref: 2,
            complaint1ref: 2,
            complaint2ref: 2,
            image_notes: '',
        },
        // Area Tecnica
        technical: {
            complexity: 3,
            tech1ref: 2,
            tech2ref: 2,
            ballsinout_ord_1ref: 0,
            ballsinout_ord_2ref: 0,
            ballsinout_sev_1ref: 0,
            ballsinout_sev_2ref: 0,
            balltouches_ord_1ref: 0,
            balltouches_sev_1ref: 0,
            firsttouch_ord_1ref: 0,
            firsttouch_sev_1ref: 0,
            penetration_ord_1ref: 0,
            penetration_ord_2ref: 0,
            penetration_sev_1ref: 0,
            penetration_sev_2ref: 0,
            posfaults_ord_1ref: 0,
            posfaults_ord_2ref: 0,
            posfaults_sev_1ref: 0,
            posfaults_sev_2ref: 0,
            nettouches_ord_1ref: 0,
            nettouches_ord_2ref: 0,
            nettouches_sev_1ref: 0,
            nettouches_sev_2ref: 0,
            walltouches_ord_1ref: 0,
            walltouches_ord_2ref: 0,
            walltouches_sev_1ref: 0,
            walltouches_sev_2ref: 0,
            airplay_ord_1ref: 0,
            airplay_sev_1ref: 0,
            defensefaults_ord_1ref: 0,
            defensefaults_ord_2ref: 0,
            defensefaults_sev_1ref: 0,
            defensefaults_sev_2ref: 0,
            servefaults_ord_1ref: 0,
            servefaults_sev_1ref: 0,
            liberofaults_ord_1ref: 0,
            liberofaults_ord_2ref: 0,
            liberofaults_sev_1ref: 0,
            liberofaults_sev_2ref: 0,
            otherfaults_ord_1ref: 0,
            otherfaults_ord_2ref: 0,
            otherfaults_sev_1ref: 0,
            otherfaults_sev_2ref: 0,
            error_notes: '',
            collab1ref: 2,
            collab2ref: 2,
            collab_notes: '',
        },
        // Area Relazionale
        relational: {
            gest_difficulty: 3,
            gest1ref: 2,
            gest2ref: 2,
            conc1ref: 2,
            conc2ref: 2,
            rel_notes: '',
        },
        // Area Disciplinare
        discipline: {
            gest_discipline: 3,
            d_verbals: 0,
            d_officials: 0,
            d_penals: 0,
            d_expulsions: 0,
            d_squalifications: 0,
            discipline: 2,
            disc_interation: 2,
            delays1ref: 2,
            delays2ref: 2,
            disc_notes: '',
        },
        // Colloquio
        interview: {
            interview1ref: 2,
            interview2ref: 2,
            interview_notes: '',
        },
        // Eventi particolari
        events: {
            events_notes: '',
            finalvote1ref: 3,
            finalvote2ref: 3
        }
    }
    const [action, setAction] = useState('new');
    const [allReports, setAllReports] = useState([]);
    const [button, setButton] = useState('new');
    const [completeReport, setCompleteReport] = useState(undefined);
    const [error, setError] = useState(false);
    const [filteredReports, setFilteredReports] = useState([]);
    const [filters, setFilters] = useState({ days: '10', startDate: '', endDate: newDate(true) });
    const [loadingData, setLoadingData] = useState(false);
    const [modal, setModal] = useState(false);
    const [report, setReport] = useState(blankReport);
    const [showAllReports, setShowAllReports] = useState(false);
    const [showReferee, setShowReferee] = useState('1st');
    const [setTitle] = useOutletContext();

    const handleButton = () => {
        if (button === 'new') {
            setReport(blankReport);
            setAction('new');
            setButton('edit');
        } else {
            setButton('new');
            setShowReferee('1st');
        }
    }

    const toggleButton = () => {
        setShowAllReports(!showAllReports);
    }

    const changeShow = e => {
        setShowReferee(e.currentTarget.value);
    }

    const exportData = async () => {
        setLoadingData(true);
        const totalReferees = await API.get('users', true)
            .catch(err => console.error(err))
        const referees = sortByName(totalReferees.users
            .filter(r => r.authrole === 2), 'lastname')
        const totalReports = await API.get(`reports?valid=true`, true).catch(err => console.error(err));
        const reports = sortByDate(totalReports.reports.filter(r =>
            new Date(r.general.date) > new Date(season.startDate)
            && new Date(r.general.date) < new Date(season.endDate)
        ), 'general.date')
        const rows = orderRows(referees, reports, season);
        const header = ["referee", "date", "vote", "group", "position", "context", "points", "average", "finalVote"]
        const worksheet = XLSX.utils.json_to_sheet(rows, {header: header});
        /* calculate column width */
        const max_width = rows.reduce((w, r) => Math.max(w, r.referee.length), 10);
        worksheet["!cols"] = [ {wch: max_width}, {wch: 11}, {wch: 8}, {wch: 15}, {wch: 8}, {wch: 12}, {wch: 8}, {wch: 8}, {wch: 8} ];
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
        XLSX.writeFile(workbook, "Report.xlsx", { compression: true });
        setLoadingData(false);
    }

    function toggleModal(e) {
        e?.preventDefault();
        setModal(!modal);
        if (modal) {
            document.querySelector('[modal-backdrop]').remove();
        } else {
            const backdropEl = document.createElement('div');
            backdropEl.setAttribute('modal-backdrop', '');
            backdropEl.classList.add('bg-gray-900', 'bg-opacity-50', 'fixed', 'inset-0', 'z-40');
            document.querySelector('body').append(backdropEl);
        }
    }

    useEffect(() => {
        setTitle('Report');
    }, [setTitle]);

    // GET dei report inseriti e ordinamento per data
    useEffect(() => {
        const queryParams = `startDate=${season.startDate}&endDate=${season.endDate}`;
        API.get(`reports?${queryParams}`, true).then(res => {
            res.success && setAllReports(sortByDate(res.reports, 'general.date'));
        });
    }, [season]);

    // Filtered Reports
    useEffect(() => {
        if (allReports.length > 0) {
            let array = [...allReports];
            // Filtro per giorni
            if (filters.days) {
                array = array.filter(r => {
                    const distance = filters.days * 24 * 60 * 60 * 1000;
                    return new Date() > new Date(r.general.date) && (new Date() - new Date(r.general.date)) < distance;
                })
            }
            // Filtra per giorno di inizio
            if (filters.startDate) {
                array = array.filter(r => new Date(r.general.date) >= new Date(filters.startDate))
            }
            // Filtra per giorno di fine
            if (filters.endDate) {
                array = array.filter(r => new Date(r.general.date) <= new Date(filters.endDate))
            }
            // Filtra tra propri e di tutti
            if (showAllReports === false) {
                array = array.filter(r => r.general.author?._id.toString() === user._id.toString())
            }
            setFilteredReports(sortByDate(array, 'general.date'));
        }
    }, [allReports, filters, showAllReports, user._id])

    return (
        <div className="flex flex-col gap-2">
            <div className="flex gap-2 items-center">
                <Button
                    size="sm"
                    onClick={handleButton}
                >
                    {button === 'new' ? 'Nuovo Report' : 'Torna ai Report'}
                </Button>
                {
                    user.authrole < 1 && button === 'new' && (
                        <ToggleSwitch
                            id="all-reports"
                            label="Tutti i report"
                            checked={showAllReports}
                            onChange={toggleButton}
                        />
                    )
                }
                {
                    user.authrole < 1 &&
                    <Button
                        size="sm"
                        disabled={loadingData}
                        onClick={exportData}
                    >
                        {loadingData && <Spinner size="sm" className="mr-1" />}
                        Esporta dati
                    </Button>
                }
                {
                    button === 'preview' && (
                        <Select
                            value={showReferee}
                            onChange={changeShow}
                        >
                            {completeReport.general.first_ref && <option value='1st'>Primo Arbitro</option>}
                            {completeReport.general.second_ref && <option value='2nd'>Secondo Arbitro</option>}
                        </Select>
                    )
                }

            </div>
            {
                button === 'new' &&
                <FiltersBar filters={filters} setFilters={setFilters} />
            }
            {
                error && (
                    <div className="danger-alert dark:bg-red-200 dark:text-red-800" role="alert">
                        {error}.
                    </div>
                )
            }
            {
                button === 'edit' &&
                <ReportForm
                    report={report}
                    setReport={setReport}
                    toggleModal={toggleModal}
                />
            }
            {
                button === 'new' &&
                <ReportTable
                    showAllReports={showAllReports}
                    reports={filteredReports}
                    setAction={setAction}
                    setButton={setButton}
                    setCompleteReport={setCompleteReport}
                    setReport={setReport}
                    setReports={setAllReports}
                    toggleModal={toggleModal}
                />
            }
            {
                button === 'preview' &&
                <ReportPreview
                    report={completeReport}
                    showReferee={showReferee}
                />
            }
            <ReportModal
                action={action}
                modal={modal}
                report={report}
                setButton={setButton}
                setError={setError}
                setReports={setAllReports}
                toggleModal={toggleModal}
            />
        </div>
    )
}

export default Reports;